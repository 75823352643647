import {isFuture} from 'date-fns'
import {youtube} from '../../client-config'
const axios = require('axios')

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

/**
 * Map GraphQL edges to nodes.
 *
 * @param data
 * @returns {*}
 */
export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(publishedAt)
}

/**
 * Build an image object to be used in gatsby-image
 *
 * @param source
 * @returns {{asset: {_ref: *}}}
 */
export function buildImageObj (source = {asset: {}}) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id}
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

/**
 * Convert Portable Text blocks to plain text.
 *
 * @param blocks
 * @returns {*}
 */
export function toPlainText (blocks) {
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

/**
 * Wrapper for the YouTube V3 API
 *
 * @param endpoint
 * @param payload
 * @param requestType
 * @returns {Promise<*>}
 */
export const youtubeV3Api = async (endpoint, payload = {}, requestType = 'get') => {
  const apiBase = `https://www.googleapis.com/youtube/v3/${endpoint}`
  const url = `${apiBase}?${buildQuery(payload)}`
  let result

  if (requestType === 'get') {
    result = await axios.get(url).then(response => {
      return response
    }, error => console.error(error))
  } else if (requestType === 'post') {
    // do post.
  }
  return result
}

/**
 * Map an array of keys/values to a valid query string.
 *
 * @param data
 * @returns {string}
 */
export const buildQuery = (data) => {
  data.key = youtube.key
  const keys = Object.keys(data)
  const values = keys.map(key => data[key])
  return keys.map((key, index) => `${encodeURIComponent(key)}=${encodeURIComponent(values[index])}`).join('&')
}

/**
 * Decode HTML Entities in a string.
 *
 * @param str
 * @returns {*}
 */
export const decodeHtmlEntity = (str) => {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec)
  })
}

export const arrayChunk = (arr, chunkSize) => {
  const chunked = []
  let i = 0
  while (i < arr.length) {
    chunked.push(arr.slice(i, chunkSize + i))
    i += chunkSize
  }

  return chunked
}
