/* @jsx jsx */
import React from 'react';
import {jsx} from '@emotion/react';
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-source-sanity';
import clientConfig from "@root/client-config";
const Image = ({src, alt, maxWidth, className}) => {
  if (!src) return null;

  let el;

  if (typeof src.asset !== 'undefined') {
    el = (
      <Img
        fluid={getFluidGatsbyImage(
          src.asset._id,
          {maxWidth},
          clientConfig.sanity
        )}
        alt={alt}
        className={className}
      />
    );
  } else {
    el = (
      <img src={src} alt={alt} className={className} style={{'maxWidth': `${maxWidth}px`}} />
    );
  }
  return el;
};

export default Image;
